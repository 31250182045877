<template>
  <div class="MonitorsList">
    <monitor-list-empty v-if="user.monitors_number === 0" />

    <template v-if="user.monitors_number > 0">
      <header class="header">
        <div class="d-flex align-items-center">
          <div class="page-header mb-0">Monitors</div>
          <icon-refresh
            ref="refreshIcon"
            class="refresh-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            @click="refresh"
          />
        </div>

        <div class="d-flex align-items-start">
          <tags-filter v-model="filters.tags" />
          <base-textbox-search
            class="search mb-0 ml-2"
            v-model="search"
            placeholder="Search..."
            :bordered="false"
          />

          <monitor-form-lite
            v-if="user.role !== 'viewer' && user.role !== 'accountant'"
            class="new-monitor mb-0 ml-2"
            @submitSuccess="onMonitorAdd($event)"
          />
        </div>
      </header>
      <header class="header-mobile">
        <div class="d-flex align-items-center">
          <div class="page-header mb-0">Monitors</div>
          <icon-refresh
            ref="refreshIcon"
            class="refresh-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            @click="refresh"
          />
          <tags-filter v-model="filters.tags" style="margin-left: 10px"/>
          <monitor-form-lite
            v-if="user.role !== 'viewer' && user.role !== 'accountant'"
            class="new-monitor mb-0 ml-2"
            @submitSuccess="onMonitorAdd($event)"
          />
        </div>
        <div class="align-items-center">
          <base-textbox-search
            class="search mb-3 ml-0 mt-3"
            v-model="search"
            placeholder="Search..."
            :bordered="false"
          />
        </div>
      </header>
      <div class="monitor-list-wrapper-mobile">
        <monitor-list-mobile ref="monitorList" :monitors="monitors" />
      </div>

      <div v-show="!loading"
        class="monitor-list-wrapper">
        <monitor-list-desktop
          :search="search"
          :filters="filters"
          ref="monitorList"
          :monitors="monitors"
          @toggle-monitors="toggleMonitors"
          @delete="onOneMonitorDelete"
          @tagsUpdated="onTagsUpdate"
          @search="onSearch"
        />
      </div>
      <monitor-list-loading v-if="loading"
      :number-of-placeholders="pagination.take"
      />

      <div class="pagination-wrapper">
        <div class="pagination-bulk">
          <monitor-bulk-actions
            v-if="selectedMonitors.length && user.role !== role.Viewer && user.role !== role.Accountant"
            :selectedMonitors="selectedMonitors"
            @pause="onMonitorsPause"
            @run="onMonitorsRun"
            @delete="onMonitorsDelete"
            @reset="onMonitorsReset"
          />
        </div>

        <div class="d-flex">
          <paginate
            :page-count="totalPages"
            :prev-text="'‹'"
            :next-text="'›'"
            class="mr-5"
            :container-class="'pagination-container'"
            :page-class="'page-item'"
            :active-class="'page-active'"
            prev-link-class="page-arrow"
            next-link-class="page-arrow"
            v-model="page"
          />

          <monitor-items-per-page @updateItemsPerPage="onPerPageChange" :per-page="monitorsPerPage"/>
        </div>

        <monitor-view-options
          @updateRunning="updateRunning"
          @updateSort="sort = $event"
          @updateFilters="statusFilter = $event"
          :user="user"
        />
      </div>
    </template>

    <tags-modal @save-bulk="onSaveBulkTags" />
    <add-modal />
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

import MonitorFormLite from '@/components/Forms/MonitorFormLite/MonitorFormLite.vue'
import MonitorListDesktop from '@/components/MonitorList/Desktop/MonitorList.vue'
import MonitorListMobile from '@/components/MonitorList/Mobile/MonitorList.vue'
import MonitorListEmpty from '@/components/MonitorList/MonitorListEmpty.vue'
import MonitorViewOptions from '@/components/MonitorList/MonitorViewOptions.vue'
import monitorsApi from '@/api/monitorsApi.js'
import TagsModal from '@/components/Monitor/MonitorTagsModal.vue'
import AddModal from '@/components/Monitor/MonitorAddModal.vue'
import TagsFilter from '@/components/MonitorList/Desktop/TagsFilter.vue'
import MonitorListLoading from '@/components/MonitorList/Desktop/MonitorListLoading.vue'
import MonitorBulkActions from '@/components/MonitorList/MonitorBulkActions.vue'
import { Role } from '@/_helpers/role'
import tippy from 'tippy.js'
import { firstMonitor, monitorsAddedSuccessfully } from '../../main'
import MonitorItemsPerPage from '@/components/MonitorList/MonitorItemsPerPage.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    MonitorItemsPerPage,
    MonitorViewOptions,
    MonitorListEmpty,
    MonitorFormLite,
    MonitorListLoading,
    MonitorListDesktop,
    MonitorListMobile,
    MonitorBulkActions,
    TagsModal,
    TagsFilter,
    AddModal,
    Paginate
  },

  metaInfo: {
    title: 'Monitors'
  },

  data () {
    return {
      page: 1,
      pagination: {
        skip: 0,
        take: 20
      },
      sort: {
        sort: 'created_at',
        sortDirection: 'asc'
      },
      statusFilter: null,
      is_running: null,
      total: 4,
      loading: false,
      search: '',
      filters: {
        tags: []
      },
      monitors: [],
      role: Role
    }
  },

  created () {
    this.onPerPageChange(this.monitorsPerPage)
    if (this.user.preferences.MonitorSortFilter) {
      this.sort = JSON.parse(this.user.preferences.MonitorSortFilter)
    }
    if (this.user.preferences.MonitorShowFilter) {
      const showFilter = JSON.parse(this.user.preferences.MonitorShowFilter)
      this.statusFilter = showFilter.status
      this.is_running = showFilter.is_running
    }
    if (!this.user.preferences.MonitorSortFilter && !this.user.preferences.MonitorShowFilter) {
      this.load(this.monitorsParams)
    }
  },

  watch: {
    monitorsParams: {
      handler: function () {
        this.refreshCurrentUser()
        this.load(this.monitorsParams)
      },
      deep: true
    },

    page () {
      this.pagination.skip = (this.page - 1) * this.pagination.take
    }
  },

  async mounted () {
    if (this.user.monitors_number > 0 && this.$mq === 'desktop') {
      tippy(this.$refs.refreshIcon.$el, {
        content: 'Refresh monitor list'
      })
    }

    firstMonitor.$on('firstMonitorAdded', (monitor) => {
      this.onMonitorAdd(monitor)
    })

    monitorsAddedSuccessfully.$on('submitSuccess', (monitor) => {
      this.onMonitorAdd(monitor)
    })
  },

  methods: {
    async onSaveBulkTags () {
      try {
        const monitorsResponse = await monitorsApi.getPaginatedMonitors(this.monitorsParams)
        this.monitors = monitorsResponse.data.map(item => {
          item.isSelected = false
          return item
        })

        this.total = monitorsResponse.meta.total
      } catch (e) {}
    },

    updateRunning (value) {
      this.is_running = value ? 0 : 1
    },
    onMonitorAdd (monitor) {
      this.$refs.monitorList.add(monitor)
    },
    async load (params) {
      this.loading = true
      const monitorsResponse = await monitorsApi.getPaginatedMonitors(params)
      this.monitors = monitorsResponse.data.map(item => {
        item.isSelected = false
        return item
      })

      this.total = monitorsResponse.meta.total
      this.loading = false
    },

    refresh () {
      this.load(this.monitorsParams)
    },

    toggleMonitors (state) {
      this.monitors = this.monitors.map(item => {
        item.isSelected = state
        return item
      })
    },

    onMonitorsPause () {
      this.$refs.monitorList.pauseSelected()
      this.toggleMonitors(false)
    },

    onMonitorsRun () {
      this.$refs.monitorList.runSelected()
      this.toggleMonitors(false)
    },

    onMonitorsDelete () {
      this.monitors = this.monitors.filter(item => !item.isSelected)
      if (this.monitors.length === 0) {
        if (this.page !== 1) {
          this.page = this.page - 1
          this.load(this.monitorsParams)
        }
        this.refreshCurrentUser()
      }
    },

    onOneMonitorDelete (id) {
      this.monitors = this.monitors.filter(item => item.id !== id)
    },

    onMonitorsReset () {
      this.toggleMonitors(false)
    },

    onTagsUpdate (value) {
      if (value.length) {
        this.monitorsParams['tags'] = value
        this.load(this.monitorsParams)
      } else {
        delete this.monitorsParams['tags']
        this.load(this.monitorsParams)
      }
    },

    onSearch (value) {
      this.monitorsParams['nameOrUrl'] = value
      this.load(this.monitorsParams)
    },

    onPerPageChange (event) {
      this.pagination.skip = 0
      this.setMonitorsPerPage(event)
      this.pagination.take = event
      this.page = 1
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ]),

    ...mapMutations('userPreferences', [
      'setMonitorsPerPage'
    ])
  },

  computed: {
    monitorsParams () {
      return {
        ...this.sort,
        ...this.pagination,
        is_running: this.statusFilter && this.statusFilter[0] !== 'everything' ? this.is_running : null,
        statusFilter: this.statusFilter
      }
    },

    totalPages () {
      return Math.ceil(this.total / this.pagination.take)
    },

    ...mapState('authentication', [
      'user'
    ]),

    ...mapState('monitors', [
      'monitorsData'
    ]),

    ...mapState('userPreferences', [
      'monitorsPerPage'
    ]),

    selectedMonitors () {
      return this.monitors.filter(item => item.isSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
 .pagination {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

   &-bulk {
     width: 147px;
   }

    &-container {
       display: flex;
       justify-content: space-between;
       padding: 0;
       list-style: none;

       ::v-deep {
         .page-active {
           background-color: rgba(0,0,0,0.1) !important;
           cursor: default;
           pointer-events: none;

           a {
             color: #4F4F4F;
           }
         }

         .page-arrow {
           color: #333333;
           opacity: 0.5;
           font-size: 20px;
         }

         li {
           margin: 0 5px;
           border-radius: 50% !important;
           cursor: pointer;

           &:hover {
             background-color:rgba(0,0,0,0.1) !important;
           }
         }

         li a {
           display: flex;
           justify-content: center;
           align-items: center;
           width: 34px;
           height: 34px;
           font-size: 15px;
           font-weight: 500;
           line-height: 18px;
           text-align: center;
           color: #333;

         }
       }
     }
  }

  .MonitorsList {
    .pagination-wrapper {
      z-index: 500;
      position: relative;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      @media (max-width: 990px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media (max-width: 767px) {
        display: none;
      }
      &-mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    .refresh-icon {
      margin: -4px 0 0 10px;
      cursor: pointer;

      &:hover,
      &:active {

        animation: reload .3s;

        @keyframes reload {
          100% { transform: rotate(180deg); }
        }
      }
    }

    .search {
      width: 250px;
    }

    .new-monitor {
      //flex: 0 0 450px;
    }

    .monitor {
      &-list {
        &-wrapper {
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
          &-mobile {
            display: none;
            @media (max-width: 767px) {
              display: block;
            }
          }

          &::v-deep div>span:empty:after {
            content: '¯\\_(ツ)_/¯';
            display: block;
            width: 100%;
            opacity: 1;
            padding: 25px;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            color: #ccc;
            background-color: #fff;
            border-radius: 8px;
            margin: 50px 0;
            visibility: visible;
          }
        }
      }
    }
  }
</style>
