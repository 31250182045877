<template>
  <div>
    <base-button ref="submit"
                 type="submit"
                 class="submit"
                 color="primary"
                 @click="openAddModal"
                 :loading="saving">Add Monitor</base-button>
    <div v-show="false">
      <hint-monitors-limit-exceeded ref="monitorsLimitExceeded" />
    </div>
  </div>
</template>

<script>
import HintMonitorsLimitExceeded from '@/components/Hints/HintMonitorsLimitExceeded.vue'

import tippy from 'tippy.js'

export default {
  components: {
    HintMonitorsLimitExceeded
  },

  data () {
    return {
      url: '',
      saving: false,
      tippy: null
    }
  },

  methods: {

    openAddModal () {
      if (this.user.exceeded_monitors_limit) {
        this.showTippy(this.$refs.monitorsLimitExceeded.$el)

        return false
      }
      this.$modal.show('monitorAdd')
    },

    showTippy (content) {
      if (this.tippy) {
        this.tippy.destroy()
      }

      this.tippy = tippy(this.$refs.submit.$el, {
        allowHTML: true,
        content: content,
        interactive: true,
        arrow: false,
        placement: 'bottom-end',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.tippy.show()
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .form {
    display: flex;
    align-items: flex-start;
  }

  .url {
    flex: 1 0;
  }

  .submit {
    flex: 0 0 120px;
    height: 40px;
    margin-left: 10px;
  }

  .error {
    color: map-get($colors, red-2);
  }

  .already-added {
    text-decoration: underline;
  }
</style>
