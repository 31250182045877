<template>
  <div class="TagsFilter">
    <base-button-icon ref="icon"
                      icon="IconTag"
                      hint="Tags" />

    <div ref="dropdown" class="dropdown">
      <base-textbox ref="search"
                    class="search bordered mb-2"
                    v-model="search"
                    :bordered="false"
                    placeholder="Search tags..." />

      <div class="tags" v-if="filteredTags.length > 0">
        <base-select-tag v-model="selectedTags"
                         class="m-0"
                         :options="filteredTags"
                         :keys="{ value: 'name', label: 'name' }"
                         :multiple="true"
                         :can-be-deleted="true"
                         @delete="onDeleteTag($event)" />
      </div>
      <div v-else>You have not set any tags for your monitors</div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  props: {
    value: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,

      selectedTags: [],
      search: ''
    }
  },

  created () {
    this.selectedTags = this.value.slice()
  },

  mounted () {
    this.setTippy()
  },

  methods: {
    setTippy () {
      this.tippyInstance = tippy(this.$refs.icon.$el, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShown: () => {
          this.open = true
          this.$refs.search.focus()
        },

        onHide: () => {
          this.open = false
        }
      })
    },

    onDeleteTag (tag) {
      this.deleteTag(tag.id)
    },

    ...mapActions('system', [
      'deleteTag'
    ])
  },

  computed: {
    filteredTags () {
      if (this.search === '') {
        return this.tags
      }

      return this.tags.filter(tag => {
        return tag.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    },

    ...mapState('system', ['tags'])
  },

  watch: {
    selectedTags: {
      handler: function (tags) {
        this.$emit('input', tags)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown {
    width: 300px;
    padding: 10px;
  }

  .tags {
    max-height: 400px;
    margin-bottom: 10px;
    overflow: auto;
  }

  /deep/ .search .input {
    //padding: 0;
  }
</style>
