<template>
  <icon-question-filled ref="icon"
                        width="12"
                        height="12"
                        color="#999ca7" />
</template>

<script>
import tippy from 'tippy.js'

export default {
  mounted () {
    tippy(this.$refs.icon.$el, {
      content: 'If the monitor is accessible at least from 80% of our locations we consider it is online.'
    })
  }
}
</script>
