<template>
  <div class="MonitorItem">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <monitor-label :monitor="monitor" />
      <monitor-status :monitor="monitor"
                      :detailed="false"
                      :stretch="false" />
    </div>

    <div class="d-flex justify-content-between">
      <div class="group">
        <div class="label">Uptime</div>
        <monitor-uptime :uptime="monitor.uptime"
                        :font-size="14" />
      </div>

      <div class="group">
        <div class="label">Response Time</div>
        <monitor-response-time :response-time="monitor.response_time"
                               :font-size="14" />
      </div>

      <div class="group">
        <div class="label">Latest Check</div>
        <monitor-latest-check :monitor="monitor"
                              :font-size="14" />
      </div>
    </div>
  </div>
</template>

<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import MonitorStatus from '@/components/Monitor/MonitorStatus.vue'
import MonitorUptime from '@/components/Common/Uptime.vue'
import MonitorLatestCheck from '@/components/Common/LatestCheck.vue'
import MonitorResponseTime from '@/components/Common/ResponseTime.vue'

export default {
  components: {
    MonitorLabel,
    MonitorStatus,
    MonitorUptime,
    MonitorResponseTime,
    MonitorLatestCheck
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorItem {
    width: 100%;
    padding: 10px;
    //border: 1px solid rgb(212, 218, 231);
    border-radius: 6px;
    background-color: #fff;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(25,34,29,0.05);
    transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;

    .label {
      margin-bottom: 2px;
      color: #999ca7;
      font-size: 14px;
    }
  }
</style>
