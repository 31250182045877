<template>
  <div class="MonitorListEmpty">
    <img class="image"
         :class="$mq"
         :src="require('@/assets/images/partying.png')" />

    <div class="intro" :class="$mq">
      <div class="title">
        Welcome to {{ appName }}
      </div>

      <div class="description" v-if="user.email_verified_at">
        Which site would you like to monitor first?
      </div>
      <div class="description" v-else>
        <AccountUnverified />
      </div>
    </div>
    <ValidationObserver ref="observer"
                        v-slot="{ handleSubmit }"
                        slim v-if="user.email_verified_at">
      <form @submit.prevent="addMonitor"
            class="form"
            :class="$mq"
            @animationend="onAnimationEnd">
        <input-monitor-url ref="url"
                           v-model="url"
                           rules="required|url"
                           :show-errors="false"
                           class="url"
                           :class="urlClasses"
                           placeholder="my-awesome-site.com" />

        <base-button type="submit"
                     class="submit"
                     color="primary"
                     :loading="saving">Go!</base-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import InputMonitorUrl from '@/components/Common/InputMonitorUrl.vue'
import { firstMonitor } from '../../main'

export default {
  components: {
    InputMonitorUrl
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME,
      url: '',
      urlClasses: {},
      saving: false
    }
  },

  mounted () {
    if (this.$refs.url) {
      this.$refs.url.focus()
    }
  },

  methods: {
    async addMonitor () {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        this.shakeUrl()
        return
      }

      this.saving = true

      const monitor = await monitorsApi.quickStore([this.url])
      await this.refreshCurrentUser()
      firstMonitor.$emit('firstMonitorAdded', monitor[0])

      this.saving = false
    },

    shakeUrl () {
      this.urlClasses = {
        animated: true,
        shake: true,
        fast: true
      }
    },

    onAnimationEnd (event) {
      if (event.animationName === 'shake') {
        this.urlClasses = {}
      }
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorListEmpty {
    @include flex-center;

    flex-direction: column;
    flex: 1 0;
    padding-bottom: 200px;

    .image {
      width: 54px;
      opacity: 1;
      margin: 40px 0 15px;

      &.desktop {
        width: 54px;
      }
    }

    .intro {
      margin-bottom: 20px;
      text-align: center;
      color: #555;

      .title {
        margin-bottom: 10px;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -.024em;
      }

      .description {
        font-size: 16px;
      }

      &.desktop .description {
        font-size: 18px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .url {
        width: 100%;
        margin-bottom: 20px;
      }

      .submit {
        width: 200px;
        height: 40px;
        margin: 0 auto;
        font-size: 16px;
      }

      &.desktop .url {
        width: 400px;
      }
    }
  }
</style>
