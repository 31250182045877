<template>
  <div ref="content"
       class="HintMonitorAlreadyAdded" style="overflow-y:auto;min-height:100px;">
    <p class="title mb-2">You have already added the following urls, please remove them. </p>
    <p v-for="(monitor, index) in monitors" v-bind:key="index">{{monitor}}</p>
  </div>
</template>

<script>

export default {
  props: {
    monitors: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .HintMonitorAlreadyAdded{
    .title {
      font-weight: bold;
    }

    .link {
      color: #fff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
</style>
