<template>
  <div class="MonitorsList" v-if="user.email_verified_at">
    <header class="header">
      <div class="section section-title section-name">
        <base-checkbox
          :value="isMonitorsSelected"
          @input="$emit('toggle-monitors', $event)"
          class="mb-0 section-checkbox"
        />
        Name
      </div>
      <div class="section section-status">
        <div class="d-flex align-items-center">
          Status
          <hint-status class="ml-2" />
        </div>
      </div>
      <div class="section section-title section-certificate">Certificate <hint-certificate class="ml-2" /> </div>
      <div class="section section-uptime">
        <div class="d-flex align-items-center">
          Uptime <hint-uptime class="ml-2" />
        </div>
      </div>
      <div class="section section-title section-latest-check">Latest Check</div>
      <div class="section section-actions"></div>
    </header>

    <draggable v-model="filteredMonitors" ghost-class="ghost" @change="onChange" :move="checkMove" animation="200">
      <transition-group type="transition" name="flip-list">
        <div :id="element.id" v-for="element in filteredMonitors" :key="element.id" class="sortable d-flex">
          <img v-if="!element.disabled" :src="require('@/assets/images/reorder-indicator.svg')" alt="" class="reorder-indicator"/>
          <monitor-item ref="monitorItem" :monitor="element" @delete="$emit('delete', $event)"/>
        </div>
      </transition-group>
    </draggable>

  </div>
  <div v-else>
    <AccountUnverified />
  </div>
</template>

<script>
import MonitorItem from './MonitorListItem/MonitorItem.vue'

import HintStatus from '@/components/Hints/HintStatus.vue'
import HintUptime from '@/components/Hints/HintUptime.vue'
import HintCertificate from '@/components/Hints/HintCertificate'
import monitorsApi from '@/api/monitorsApi.js'

import draggable from 'vuedraggable'

export default {
  components: {
    MonitorItem,
    HintStatus,
    HintUptime,
    HintCertificate,
    draggable
  },

  props: {
    search: {
      required: false,
      type: String,
      default: ''
    },

    filters: {
      required: true,
      type: Object
    },

    monitors: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      loading: false,
      arranging: false,
      clonedMonitors: [],
      isMonitorsSelected: false,
      timeout: null
    }
  },

  methods: {
    // TODO: Is it possible to refactor this pattern?
    async load () {
      // THIS IS AN EXAMPLE OF HOW DATA SHOULD BE SENT TO GOOGLE ANALYTICS IN A PURCHASE EVENT CASE
      // IT SHOULD BE TRIGGERED ONLY IF THE TRANSACTION WAS SUCCESSFUL
      // this.$gtag.purchase({
      //   'transaction_id': '2323',
      //   'affiliation': 'NO AFFILIATION',
      //   'value': '1001',
      //   'items': [
      //     {
      //       'id': '2323',
      //       'name': 'Pantaloni cu maneca scurta',
      //       'brand': 'Spark',
      //       'category': 'camasi barbati',
      //       'price': 100.44
      //     }
      //   ]
      // })

      this.loading = true
      this.clonedMonitors = await monitorsApi.get(this.filters)
      this.loading = false
    },

    add (monitor) {
      if (this.clonedMonitors.length) {
        if (Array.isArray(monitor)) {
          monitor.forEach(item => this.clonedMonitors.push({ ...item, isSelected: false }))
        } else {
          this.clonedMonitors.unshift({ ...monitor, isSelected: false })
        }
      } else {
        if (Array.isArray(monitor)) {
          monitor.forEach(item => this.monitors.unshift({ ...item, isSelected: false }))
        } else {
          this.monitors.unshift({ ...monitor, isSelected: false })
        }
      }
    },

    onChange: async function (event) {
      const monitorId = event.moved.element.id
      const newIndex = event.moved.newIndex
      const oldIndex = event.moved.oldIndex
      const data = { id: monitorId, oldPosition: oldIndex, newPosition: newIndex }
      try {
        this.arranging = true
        await monitorsApi.arrangeMonitors(data)
      } catch (error) {
        console.error(error)
      }
      this.arranging = false
    },

    checkMove: function () {
      return (this.search === '' && this.arranging === false)
    },

    pauseSelected () {
      if (this.$refs.monitorItem) {
        this.$refs.monitorItem.forEach(item => {
          if (item.monitor && item.monitor.isSelected) {
            item.onPause()
          }
        })
      }
    },

    runSelected () {
      if (this.$refs.monitorItem) {
        this.$refs.monitorItem.forEach(item => {
          if (item.monitor && item.monitor.isSelected) {
            item.onRun()
          }
        })
      }
    }
  },

  computed: {
    tags: function () {
      return this.filters.tags
    },
    filteredMonitors: {
      set: function (newValue) {
        if (this.clonedMonitors.length) {
          this.clonedMonitors = newValue
        } else {
          this.monitors = newValue
        }
      },
      get: function (k) {
        if (this.clonedMonitors.length) {
          if (this.search === '') {
            return this.clonedMonitors
          }

          return this.clonedMonitors.filter(monitor => {
            const url = monitor.url.toLowerCase()
            const name = monitor.name ? monitor.name.toLowerCase() : null
            const search = this.search.toLowerCase()

            return url.includes(search) || (name && name.includes(search))
          })
        } else {
          return this.monitors
        }
      }
    },
    ...mapState('authentication', ['user'])
  },

  watch: {
    tags: {
      handler: function (newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.$emit('tagsUpdated', newVal)
        }
      },
      deep: true
    },
    monitors: {
      handler: function () {
        this.isMonitorsSelected = this.monitors.every(item => item.isSelected)
      },
      deep: true
    },
    search: {
      handler: function (value) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => { this.$emit('search', value) }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorsList {
    .header {
      display: flex;
      margin-bottom: 10px;
      color: #999ca7;
      justify-content: space-between;
    }

    .sortable {
      position: relative;
      margin: 20px 0;

      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: 30px;
        height: 100%;
      }
    }

    .sortable-drag {
      opacity: 0;
    }
    .flip-list-move {
      transition: transform 0s
    }

    .ghost {
      opacity: .5;
    }

    .reorder-indicator {
      opacity: 0;
      cursor: move;
      position: relative;
      left: -25px;
      //padding-right:15px;
      margin-right: -11px;
      //transition: 0.25s;
    }

    .sortable:hover > .reorder-indicator {
      opacity: 1;
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
      box-shadow: none;
    }
    .section-title {
      white-space: nowrap;
    }

    .section-name {
      flex: 0 0 180px !important;
    }

    .section-checkbox {
      margin-right: 13px;
    }

    ::v-deep {
      @media (max-width: 1200px) {
        .section {
          padding: 0 10px !important;
        }

        .section-name {
          flex: 0 0 120px !important;
        }

        .section-status, .section-certificate {
          flex: 0 0 120px !important;
        }

        .section-uptime {
          flex: 0 0 120px !important;
        }

        .section-latest-check {
          flex: 0 0 120px !important;
        }
      }
      @media (max-width: 990px) {
        .section-name {
          flex: 0 0 110px !important;
        }
        .section-uptime {
          flex: 0 0 90px !important;
        }
      }
    }

    @include monitor-item-columns;
  }
</style>
