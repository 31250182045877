<template>
  <base-modal name="monitorTags"
              width="550px"
              height="auto"
              @before-open="beforeOpen($event)">
    <template #header>
      {{ monitor ? `Edit tags for ${monitor.nameOrUrl.replace("https://", "").replace("http://", "")}` : "Add tags" }}
    </template>

    <div class="description">Tags may be used for easily filtering monitors list e.g. if you have groups of related monitors.</div>

    <base-tags-input v-if="monitor"
                     :autocomplete-tags="tags"
                     v-model="monitor.tags" />

    <base-tags-input v-else-if="monitors"
                     :autocomplete-tags="tags"
                     v-model="bulkTags" />

    <template #footer>
      <div class="toolbar justify-content">
        <base-button type="button"
                     @click="save"
                     :loading="saving">Save</base-button>

        <base-button type="button"
                     color="light"
                     :outlined="true"
                     @click="$modal.hide('monitorTags')">Cancel</base-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'

export default {
  data () {
    return {
      monitor: null,
      monitors: [],
      bulkTags: [],
      saving: false
    }
  },

  beforeMount () {
    this.monitor = null
  },

  methods: {
    beforeOpen (event) {
      this.monitor = event.params?.monitor ?? null
      this.monitors = event.params?.monitors ?? null
    },

    async save () {
      this.saving = true

      // This is kind of hacky solution because we need to wait a bit
      // till the input event fired from base-tags-input
      setTimeout(async () => {
        if (this.monitors) {
          const tags = this.bulkTags.map(tag => tag.name.trim())
          await monitorsApi.saveBulkTags(this.monitors.map(monitor => monitor.id), tags)
          this.bulkTags = []
          this.$emit('save-bulk')
        } else {
          const tags = this.monitor.tags.map(tag => tag.name)
          await monitorsApi.saveTags(this.monitor.id, tags)
        }
        await this.loadTags()
        this.saving = false

        this.$modal.hide('monitorTags')
      }, 10)
    },

    ...mapActions('system', [
      'loadTags'
    ])
  },

  computed: {
    ...mapState('system', ['tags'])
  }
}
</script>

<style lang="scss" scoped>
  .content {
    padding: 20px;
  }

  // .modal-body,
  // .modal-footer {

  // }

  .description {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
</style>
