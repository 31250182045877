<template>
  <div class="MonitorCertificateHealth">
    <template v-if="!showDropdown">
      <div class="badge"
           :class="classes">
        <div class="status-text">
          {{ monitor.ssl_certificate_state | sslCertificateState }}
        </div>
      </div>
    </template>
    <template v-else>
      <div ref="badge"
           class="badge"
           :class="classes">
        <div class="status-text">
          {{ monitor.ssl_certificate_state | sslCertificateState }}
        </div>
        <icon-dropdown width="16"
                       height="16"
                       color="#155724"
                       class="icon-dropdown" />
      </div>
      <certificate-dropdown ref="dropdown"
                            :monitor="monitor" />
    </template>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import CertificateDropdown from './MonitorCertificateDropdown.vue'

export default {
  components: {
    CertificateDropdown
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },

    detailed: {
      required: false,
      type: Boolean,
      default: true
    },

    stretch: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      tippy: null
    }
  },

  methods: {
    setTippy () {
      if (this.tippyInstance || this.tippy) {
        return
      }
      this.tippy = tippy(this.$refs.badge, {
        content: this.$refs.dropdown.$el,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none'
      })
    },

    removeTippy () {
      if (this.tippy) {
        this.tippy.destroy()
      }
    }
  },

  computed: {
    classes () {
      let classes = []

      if (this.showDropdown) {
        classes.push('badge-hoverable')
      }

      let possibleStatuses = ['not_found', 'valid', 'invalid', 'off']
      if (!possibleStatuses.includes(this.monitor.ssl_certificate_state)) {
        classes.push('checking')
      } else {
        classes.push(this.monitor.ssl_certificate_state)
      }

      if (this.stretch) {
        classes.push('stretch')
      } else {
        classes.push('inline')
      }

      return classes
    },

    showDropdown () {
      if (!this.monitor.ssl_certificate) {
        return false
      }

      return this.detailed
    }
  },

  watch: {
    monitor: {
      handler: function () {
        this.$nextTick(() => {
          if (this.showDropdown) {
            this.setTippy()
          } else {
            this.removeTippy()
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorCertificateHealth {
    .badge {
      @include badge;

      display: flex;
      align-items: center;

      &.inline {
        display: inline-flex;
      }

      .icon-dropdown {
        transition: 0.2s all;
      }

      &[aria-expanded = "true"] .icon-dropdown {
        transform: rotate(180deg);
      }

      .status-text {
        flex: 1 0;
      }

      &.valid {
        @include badge-success;
      }

      &.off {
        @include badge-disabled;
        background-color: #eee;
      }

      &.invalid {
        @include badge-danger;
      }

      &.checking, &.not_found {
        @include badge-warning;
      }

      &.checking {
        @include badge-warning;

        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeholderAnimate;
        background: #f6f7f8; // Fallback
        background: linear-gradient(to right, #ffdf80 2%, #ffecb3 18%, #ffdf80 33%);
        background-size: 1300px; // Animation Area
      }
    }

    .dropdown {
      padding: 10px 10px 0 10px;
      width: 450px;

      .title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .content {
        display: flex;
        align-items: flex-start;
      }

      .icon-wrapper {
        flex: 0 0 64px;

        .icon-ssl {
          transform: translate(-10px, -12px);
        }
      }

      .details {
        flex: 1 0;

        .line {
          max-width: 350px;
        }

        .domain {
          font-weight: bold;
        }

        .ssl-status {
          font-weight: bold;

          &.valid {
            color: map-get($badge-colors, success-text);
          }

          &.invalid {
            color: map-get($badge-colors, danger-text);
          }
        }
      }
    }

    @keyframes placeholderAnimate {
      0%{ background-position: -650px 0; }
      100%{ background-position: 650px 0; }
    }
  }
</style>
