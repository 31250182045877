<template>
  <icon-pause ref="indicator"
              width="16"
              height="16" />
</template>

<script>
import tippy from 'tippy.js'

export default {
  data () {
    return {
      tippyInstance: null
    }
  },

  mounted () {
    this.tippyInstance = tippy(this.$refs.indicator.$el, {
      content: 'Paused'
    })
  },

  methods: {
    triggerHint () {
      this.tippyInstance.show(300)

      setTimeout(() => {
        this.tippyInstance.hide(600)
      }, 1000)
    }
  }
}
</script>
