<template>
  <div class="LatestCheck" :style="styles">
    <div class="placeholder" v-if="!monitor.latest_check"></div>
    <router-link :to="latestCheckRoute"
                 v-else>
      {{ monitor.latest_check.created_at | shortDate }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    },
    fontSize: {
      required: false,
      type: Number,
      default: 16
    }
  },

  computed: {
    latestCheckRoute () {
      return {
        name: 'monitors.single.checks.single',
        params: {
          id: this.monitor.id,
          checkId: this.monitor.latest_check.id
        }
      }
    },

    styles () {
      return {
        'font-size': this.fontSize + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .LatestCheck {
    .placeholder {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: #f9f9f9;
    }
  }
</style>
