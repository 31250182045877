<template>
  <div class="MonitorCertificateDropdown">
    <div ref="dropdown"
         class="dropdown">
      <div class="title">SSL Certificate Details</div>
      <div class="content">
        <div class="details">
          <div class="domain truncate">
            {{ monitor.ssl_certificate.domain }}
          </div>

          <div class="line">
            Issued by: {{ monitor.ssl_certificate.issued_by }}
          </div>

          <div class="line">
            Expires at: {{ monitor.ssl_certificate.expires_at | longDate }}
          </div>

          <div class="line">
            This certificate is
            <span class="ssl-status" :class="classes">
              {{ monitor.ssl_certificate_state | sslCertificateState }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  computed: {
    classes () {
      return {
        [this.monitor.ssl_certificate_state]: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorCertificateDropdown {
    padding: 10px;
    width: 300px;

    .title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      align-items: flex-start;
    }

    .icon-wrapper {
      flex: 0 0 64px;

      .icon-ssl {
        transform: translate(-10px, -12px);
      }
    }

    .details {
      flex: 1 0;

      .line {
        opacity: 1;
        font-size: 13px;
        line-height: 1.4;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .domain {
        font-weight: 600;
        margin-bottom: 6px;
        font-size: 15px;
      }

      .ssl-status {
        font-weight: 500;

        &.valid {
          color: map-get($colors, green-1);
        }

        &.invalid {
          color: map-get($colors, red-1);
        }
      }
    }
  }
</style>
