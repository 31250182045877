<template>
  <div class="HintMonitorsLimitExceeded">
    <p class="title mb-2">You have exceeded the monitors number limit</p>
    <p>
      Your current limit is {{user.monitors_limit}} monitors.
      If you want to increase this limit, please see our <a style="color: #6551E0" href="#" @click.prevent="redirectToAddons">addons</a>.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    email () {
      return process.env.VUE_APP_SUPPORT_EMAIL
    },

    ...mapState('authentication', [
      'user'
    ])
  },
  methods: {
    redirectToAddons () {
      window.location.href = '/account/addons'
    }
  }
}
</script>

<style lang="scss" scoped>
  .HintMonitorsLimitExceeded {
    .title {
      font-weight: bold;
    }

    .link {
      color: #fff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
</style>
