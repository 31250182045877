<template>
  <div ref="content"
       class="HintInvalidUrl">
    <p class="title mb-2">This url is not found</p>
    <p class="mb-1">We could not find any DNS records for this url. Seems like the url is not valid or not accessible at all.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .HintInvalidUrl {
    .title {
      font-weight: bold;
    }

    .link {
      color: #fff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
</style>
