<template>
  <div class="HintNoActiveSubscription">
    <p class="title mb-2">Inactive Subscription</p>
    <p>Your subscription is currently inactive. Please verify your payment method or complete the necessary verification to avoid service interruption.</p>
  </div>
</template>

<script>
export default {
  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.HintNoActiveSubscription {
  .title {
    font-weight: bold;
  }

  .link {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>
