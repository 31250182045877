<template>
  <ValidationObserver ref="observer"
                      tag="div">
    <base-modal name="monitorAdd"
                width="500px"
                height="auto"
                overflow="auto"
                >
      <template #header>
        Add Website Monitors
      </template>

      <div class="description">For multiple monitors, separate each URL with a comma.</div>

      <base-textarea
        style="margin-bottom: -17px"
        ref="message"
        rows="5"
        v-model="urls"
        rules="required|urls"
        placeholder="Example: google.com,facebook.com,twitter.com"
        @submit="addMonitor"
        is-submit-on-enter
      />

      <template #footer>
        <div class="toolbar justify-content">
          <base-button ref="submit"
                       type="button"
                       @click="addMonitor"
                       :loading="saving">Add Monitors</base-button>

          <base-button type="button"
                       color="light"
                       :outlined="true"
                       @click="$modal.hide('monitorAdd')">Cancel</base-button>
        </div>
      </template>
      <div v-show="false">
        <hint-monitors-limit-exceeded ref="monitorsLimitExceeded" />
        <hint-monitor-already-added ref="monitorAlreadyAdded"
                                    :monitors="existingMonitors"
                                    v-if="existingMonitors" />
        <hint-invalid-url ref="invalidUrl" />
        <hint-no-active-subscription ref="noActiveSubscription"/>
      </div>
    </base-modal>
  </ValidationObserver>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import HintMonitorsLimitExceeded from '@/components/Hints/HintMonitorsLimitExceeded.vue'
import HintMonitorAlreadyAdded from '@/components/Hints/HintMonitorAlreadyAdded.vue'
import HintInvalidUrl from '@/components/Hints/HintInvalidUrl.vue'
import { monitorsAddedSuccessfully } from '../../main'
import tippy from 'tippy.js'
import lodash from 'lodash'
import HintNoActiveSubscription from '@/components/Hints/HintNoActiveSubscription.vue'
export default {
  components: {
    HintNoActiveSubscription,
    HintMonitorsLimitExceeded,
    HintMonitorAlreadyAdded,
    HintInvalidUrl
  },

  data () {
    return {
      saving: false,
      urls: '',
      existingMonitors: null,
      protocol: 'https://',
      urlsList: null,
      tippy: null
    }
  },

  methods: {

    async addMonitor () {
      this.urls = this.urls.replace(/\s*,\s*/g, ',')
      this.urlsList = this.urls.split(',')
      for (const url in this.urlsList) {
        if (!(this.urlsList[url].substring(0, 7) === 'http://') && !(this.urlsList[url].substring(0, 8) === 'https://')) {
          this.urlsList[this.urlsList.indexOf(this.urlsList[url])] = this.protocol + this.urlsList[url]
        }
      }

      if (this.user.exceeded_monitors_limit) {
        this.showTippy(this.$refs.monitorsLimitExceeded.$el)

        return false
      }

      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.saving = true

      const chunks = lodash.chunk(this.urlsList, 10)

      try {
        const monitors = []

        for (const chunk of chunks) {
          const response = await monitorsApi.quickStore(chunk)
          monitors.push(response)
        }
        await this.refreshCurrentUser()

        this.urls = ''
        this.urlsList = []
        this.$refs.observer.reset()
        for (const index in monitors) {
          monitorsAddedSuccessfully.$emit('submitSuccess', monitors[index])
        }
        this.$modal.hide('monitorAdd')
      } catch (error) {
        const response = error.response.data

        if (response.active_url === false) {
          this.showTippy(this.$refs.invalidUrl.$el)
        }
        if (error.response.status === 403) {
          this.showTippy(this.$refs.monitorsLimitExceeded.$el)
        }

        if (error.response.status === 402) {
          this.showTippy(this.$refs.noActiveSubscription.$el)
        }

        if (response.monitor_not_unique) {
          this.existingMonitors = response.monitors
          this.$nextTick(() => {
            this.showTippy(this.$refs.monitorAlreadyAdded.$el)
          })
        }
      }

      this.saving = false
    },

    ...mapActions('system', [
      'loadTags'
    ]),

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ]),

    showTippy (content) {
      if (this.tippy) {
        this.tippy.destroy()
      }

      this.tippy = tippy(this.$refs.submit.$el, {
        allowHTML: true,
        content: content,
        interactive: true,
        arrow: false,
        placement: 'top-end',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme',
        offset: [10, 10]
      })

      this.tippy.show()
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  },

  watch: {
    urls () {
      this.existingMonitor = null
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}

// .modal-body,
// .modal-footer {

// }

.description {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 10px;
}
</style>
