<template>
  <div class="MonitorsList">
    <draggable v-model="monitors" ghost-class="ghost" @change="onChange" :move="checkMove">
      <transition-group type="transition" name="flip-list">
        <div class="sortable" :id="element.id" v-for="element in monitors" :key="element.id">
          <monitor-item :monitor="element" class="sortable" @delete="$emit('delete', $event)"/>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import MonitorItem from './MonitorListItem/MonitorItem.vue'

import monitorsApi from '@/api/monitorsApi.js'
import draggable from 'vuedraggable'

export default {
  components: {
    MonitorItem,
    draggable
  },

  props: {
    monitors: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      // monitors: [],
      loading: false,
      arranging: false
    }
  },

  methods: {
    getItemComponent (monitor) {
      // TODO: Do not hardcode name?
      if (monitor.checking) {
        return 'MonitorPlaceholder'
      }

      return 'MonitorItem'
    },

    add (monitor) {
      this.monitors.unshift(monitor)
    },

    onChange: async function (event) {
      const monitorId = event.moved.element.id
      const newIndex = event.moved.newIndex
      const oldIndex = event.moved.oldIndex
      const data = { id: monitorId, oldPosition: oldIndex, newPosition: newIndex }
      try {
        this.arranging = true
        await monitorsApi.arrangeMonitors(data)
      } catch (error) {
        console.error(error)
      }
      this.arranging = false
    },

    checkMove: function () {
      return (this.search === '' && this.arranging === false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorsList {
    .header {
      margin-bottom: 10px;
      color: #999ca7;
    }
    .sortable-drag {
      opacity: 0;
    }
    .flip-list-move {
      transition: transform 0.5s
    }

    .ghost {
      border-left: 6px solid #7918F2;
      box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
      opacity: .7;
    }

    @include monitor-item-columns;
  }
</style>
